import type { ReactNode } from 'react';

type IBackgroundProps = {
  children: ReactNode;
  color?: string;
  style?: any;
};

const Background = (props: IBackgroundProps) => (
  <div style={props.style} className={props.color}>
    {props.children}
  </div>
);

export { Background };
