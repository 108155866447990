import classNames from 'classnames';

interface IProps {
  xl?: boolean;
  secondary?: boolean;
  full?: boolean;
  children?: any;
}

const Button = ({ xl, secondary, full, children }: IProps) => {
  const btnClass = classNames('inline-block rounded-md text-center font-semibold', {
    'text-lg py-2 px-4': !xl,
    'font-extrabold text-xl py-4 px-6': xl,
    'text-white bg-primary-500 hover:bg-primary-600': !secondary,
    'bg-gray-300 hover:bg-gray-400': secondary,
    'w-full': full,
  });

  return <div className={btnClass}>{children}</div>;
};

export { Button };
