import { FaCode } from 'react-icons/fa6';
import { LuWand } from 'react-icons/lu';
import { MdLightbulbOutline } from 'react-icons/md';

import { Background } from '../background/Background';
import { FadeIn } from '../background/FadeIn';
import { Button } from '../button/Button';
import { HeroOneButton } from '../hero/HeroOneButton';
import { Section } from '../layout/Section';

const heroDesc = () => {
  return (
    <div className="py-4 text-left">
      <ul style={{ listStyleType: 'none' }}>
        <li className="flex items-center pb-2">
          <div className="shrink-0 rounded bg-slate-700 p-2">
            <MdLightbulbOutline className="text-cyan-400" />
          </div>
          <div className="ml-4">Scale from clinical trial to commercialization on the same platform.</div>
        </li>
        <li className="flex items-center pb-2">
          <div className="shrink-0 rounded bg-slate-700 p-2">
            <LuWand className="text-cyan-400" />
          </div>
          <div className="ml-4">
            Use pre-built components to go-to-market fast with your behavioral and nutritional interventions.
          </div>
        </li>
        <li className="flex items-center pb-2">
          <div className="shrink-0 rounded bg-slate-700 p-2">
            <FaCode className="text-cyan-400" />
          </div>
          <div className="ml-4">
            No coding required to be able to deliver clinical treatments in a consumer friendly interface.
          </div>
        </li>
      </ul>
    </div>
  );
};

interface IFeaturedProps {
  button?: boolean;
}

export const Featured = (props: IFeaturedProps) => {
  // if props.button is not `false`, render the button
  let button: JSX.Element | null = null;
  if (props.button !== false) {
    button = (
      <div className="w-100 flex justify-around">
        <a href="/demo">
          {/* button not rendering as button */}
          <Button>Get Started</Button>
        </a>
      </div>
    );
  }
  return (
    <div>
      <Background color="text-gray-600 bg-slate-900">
        <Section yPadding="py-20">
          <span className="text-cyan-400">Weller Labs</span>
          <FadeIn>
            <HeroOneButton
              title={'Our specialized platform makes treatment authoring simple.'}
              titleClassName="text-white pt-5"
              description={heroDesc()}
              button={button}
              image={{
                src: '/assets/images/cms.png',
                alt: 'Hero screenshot',
              }}
            />
          </FadeIn>
        </Section>
      </Background>
    </div>
  );
};
