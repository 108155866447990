import cx from 'classnames';
import type { ReactNode } from 'react';

type ISectionProps = {
  title?: string;
  subtitle?: string;
  description?: string;
  yPadding?: string;
  className?: string;
  children: ReactNode;
};

const Section = (props: ISectionProps) => (
  <div
    className={cx(
      `mx-auto max-w-screen-xl px-3 sm:px-5 lg:px-6 ${props.yPadding ? props.yPadding : 'py-16'}`,
      props.className
    )}
  >
    {(props.title || props.subtitle || props.description) && (
      <div className="mx-auto mb-12 w-full text-center md:w-4/5 lg:w-3/4 xl:w-2/3">
        {props.subtitle && <div className="text-sm font-bold text-primary-500">{props.subtitle}</div>}
        {props.title && <h2 className="text-4xl font-bold text-gray-900">{props.title}</h2>}
        {props.description && <div className="mt-4 text-xl">{props.description}</div>}
      </div>
    )}

    {props.children}
  </div>
);

export { Section };
