import cx from 'classnames';
import { useInView } from 'react-intersection-observer';

interface FadeInProps {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  threshold?: number; // 0-1
}

export const FadeIn: React.FC<FadeInProps> = (props: FadeInProps) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '50px 0px', // Adjusts when the animation starts
    threshold: props.threshold ?? 0.5,
  });

  return (
    <div id={props.id} ref={ref} className={cx(props.className, inView ? 'animate-fade' : 'opacity-0')}>
      {props.children}
    </div>
  );
};
