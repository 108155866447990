import cx from 'classnames';
import type { ReactNode } from 'react';

import { FadeIn } from '../background/FadeIn';

type IHeroOneButtonProps = {
  title: ReactNode;
  titleClassName?: string;
  description: string | ReactNode;
  descriptionClassName?: string;
  button: ReactNode;
  image?: {
    src: string;
    alt: string;
  };
};

const HeroOneButton = (props: IHeroOneButtonProps) => (
  <div className="flex flex-wrap items-center text-center lg:text-left">
    <div className="items w-full md:mx-auto md:w-4/5 lg:w-1/2 lg:pr-4">
      <h1 className={cx('whitespace-pre-line text-5xl font-bold leading-hero text-gray-900', props.titleClassName)}>
        {props.title}
      </h1>
      <div className={cx('mt-4 mb-6 text-2xl max-w-xl', props.descriptionClassName)}>{props.description}</div>

      {props.button}
    </div>

    {props.image && (
      <FadeIn className="w-full lg:w-1/2">
        <img className="mx-auto mt-12 rounded-md lg:mt-0" src={props.image.src} alt={props.image.alt} />
      </FadeIn>
    )}
  </div>
);

export { HeroOneButton };
